// 2021-12-03

/**
 * isPrefixed is used to prefix selected elements with short text to indicate where the
 * element's text is located e.g. a ButterCMS dictionary collection.
 *
 * technical details:
 *   isPrefixed is a boolean value saved in the store.
 *   isPrefixed is set and cleared by button "Show Daniel" / "Hide Daniel" in the page equilateral-turnip.
 */

import { butter } from '~/plugins/buttercms'
import { STATE_ISO_CODE } from '~/utils/VFAParameters'

async function retrieveButter(store, prefix) {
  let promiseArray = []

  if (!store.state.butterA11yDictionary) {
    let bpromise = butter.content
      .retrieve(['a11ydictionary'])
      .then((res) => {
        store.commit('saveButterA11yDictionary', res.data.data.a11ydictionary)
        console.log(prefix + ' retrieved: a11ydictionary')
      })
      .catch((err) => {
        console.log('error retrieving: a11ydictionary  ' + err.message)
      })
    promiseArray.push(bpromise)
  } else {
    console.log(prefix + ' already retrieved: a11ydictionary')
  }

  if (!store.state.butterBanners) {
    let bpromise = butter.content
      .retrieve(['banners'])
      .then((res) => {
        store.commit('saveButterBanners', res.data.data.banners)
        console.log(prefix + ' retrieved: banners')
      })
      .catch((err) => {
        console.log('error retrieving: banners  ' + err.message)
      })
    promiseArray.push(bpromise)
  } else {
    console.log(prefix + ' already retrieved: banners')
  }

  if (process.env.isStudentSite !== 'true') {
    if (!store.state.butterDAGlobalPresidentialPrimary) {
      let bpromise = butter.content
        .retrieve(['da_global_presidential_primary'])
        .then((res) => {
          store.commit('saveButterDAGlobalPresidentialPrimary', res.data.data.da_global_presidential_primary)
          console.log(prefix + ' retrieved: da_global_presidential_primary')
        })
        .catch((err) => {
          console.log('error retrieving: da_global_presidential_primary  ' + err.message)
        })
      promiseArray.push(bpromise)
    } else {
      console.log(prefix + ' already retrieved: da_global_presidential_primary')
    }
  }

  if (!store.state.butterDictionary) {
    let bpromise = butter.content
      .retrieve(['dictionary'])
      .then((res) => {
        store.commit('saveButterDictionary', res.data.data.dictionary)
        console.log(prefix + ' retrieved: dictionary')
      })
      .catch((err) => {
        console.log('error retrieving: dictionary  ' + err.message)
      })
    promiseArray.push(bpromise)
  } else {
    console.log(prefix + ' already retrieved: dictionary')
  }

  if (!store.state.butterFaqCategories) {
    let bpromise = butter.content
      .retrieve(['faqcategories'])
      .then((res) => {
        store.commit('saveButterFaqCategories', res.data.data.faqcategories)
        console.log(prefix + ' retrieved: faqcategories')
      })
      .catch((err) => {
        console.log('error retrieving: faqcategories  ' + err.message)
      })
    promiseArray.push(bpromise)
  } else {
    console.log(prefix + ' already retrieved: faqcategories')
  }

  if (!store.state.butterFaqQuestions) {
    let bpromise = butter.content
      .retrieve(['faqquestions'])
      .then((res) => {
        store.commit('saveButterFaqQuestions', res.data.data.faqquestions)
        console.log(prefix + ' retrieved: faqquestions')
      })
      .catch((err) => {
        console.log('error retrieving: faqquestions  ' + err.message)
      })
    promiseArray.push(bpromise)
  } else {
    console.log(prefix + ' already retrieved: faqquestions')
  }

  if (!store.state.butterLeos) {
    let bpromise = butter.content
      .retrieve(['local_election_officials-revisions'])
      .then((res) => {
        let leos = res.data.data['local_election_officials-revisions']
        // for filtering out fake states
        leos = leos.filter(x => STATE_ISO_CODE.includes(x.s_state.slice(0, 2).toUpperCase()))
        store.commit('saveButterLeos', leos)
        // store.commit('saveButterLeos', res.data.data['local_election_officials-revisions'])
        console.log(prefix + ' retrieved: local_election_officials-revisions')
      })
      .catch((err) => {
        console.log('error retrieving: local_election_officials-revisions  ' + err.message)
      })
    promiseArray.push(bpromise)
  } else {
    console.log(prefix + ' already retrieved: local_election_officials-revisions')
  }

  if (!store.state.butterSiteInfo) {
    let bpromise = butter.content
      .retrieve(['site_info'])
      .then((res) => {
        store.commit('saveButterSiteInfo', res.data.data.site_info)
        console.log(prefix + ' retrieved: site_info')
      })
      .catch((err) => {
        console.log('error retrieving: site_info  ' + err.message)
      })
    promiseArray.push(bpromise)
  } else {
    console.log(prefix + ' already retrieved: site_info')
  }

  if (!store.state.butterStateElections) {
    let bpromise = butter.content
      .retrieve(['state_elections'])
      .then((res) => {
        let elections = res.data.data.state_elections
        // for filtering out fake states
        elections = elections.filter(x => STATE_ISO_CODE.includes(x.stateid.slice(0, 2)))
        store.commit('saveButterStateElections', elections)
        // store.commit('saveButterStateElections', res.data.data.state_elections)
        console.log(prefix + ' retrieved: state_elections')
      })
      .catch((err) => {
        console.log('error retrieving: state_elections  ' + err.message)
      })
    promiseArray.push(bpromise)
  } else {
    console.log(prefix + ' already retrieved: state_elections')
  }

  if (!store.state.butterStateVotingRules) {
    let bpromise = butter.content
      .retrieve(['state_voting_rules'])
      .then((res) => {
        let voting_rules = res.data.data.state_voting_rules
        // for filtering out fake states
        voting_rules = voting_rules.filter(x => STATE_ISO_CODE.includes(x.stateid.slice(0, 2)))
        store.commit('saveButterStateVotingRules', voting_rules)
        console.log(prefix + ' retrieved: state_voting_rules')
      })
      .catch((err) => {
        console.log('error retrieving: state_voting_rules  ' + err.message)
      })
    promiseArray.push(bpromise)
  } else {
    console.log(prefix + ' already retrieved: state_voting_rules')
  }

  if (!store.state.butterStateVotingRulesFieldDescriptions) {
    let bpromise = butter.content
      .retrieve(['state_voting_rules_field_descriptions'])
      .then((res) => {
        store.commit('saveButterStateVotingRulesFieldDescriptions', res.data.data.state_voting_rules_field_descriptions)
        console.log(prefix + ' retrieved: state_voting_rules_field_descriptions')
      })
      .catch((err) => {
        console.log('error retrieving: state_voting_rules_field_descriptions  ' + err.message)
      })
    promiseArray.push(bpromise)
  } else {
    console.log(prefix + ' already retrieved: state_voting_rules_field_descriptions')
  }

  if (!store.state.butterVFAGraphics) {
    let bpromise = butter.content
      .retrieve(['vfa_graphics'])
      .then((res) => {
        store.commit('saveButterVFAGraphics', res.data.data.vfa_graphics[0])
        console.log(prefix + ' retrieved: vfa_graphics')
      })
      .catch((err) => {
        console.log('error retrieving: vfa_graphics  ' + err.message)
      })
    promiseArray.push(bpromise)
  } else {
    console.log(prefix + ' already retrieved: vfa_graphics')
  }

  if (!store.state.butterVFAParameters) {
    let bpromise = butter.content
      .retrieve(['vfa_parameters'])
      .then((res) => {
        store.commit('saveButterVFAParameters', res.data.data.vfa_parameters[0])
        console.log(prefix + ' retrieved: vfa_parameters')
      })
      .catch((err) => {
        console.log('error retrieving: vfa_parameters  ' + err.message)
      })
    promiseArray.push(bpromise)
  } else {
    console.log(prefix + ' already retrieved: vfa_parameters')
  }

  if (!store.state.butterVolunteerData && process.env.stage !== 'prod') {
    let bpromise = butter.content
      .retrieve(['volunteer'])
      .then((res) => {
        store.commit('saveButterVolunteerData', res.data.data.volunteer)
        console.log(prefix + ' retrieved: volunteer')
      })
      .catch((err) => {
        console.log('error retrieving: volunteer ' + err.message)
      })
    promiseArray.push(bpromise)
  } else {
    console.log(prefix + ' already retrieved: volunteer')
  }

  if (!store.state.butterVoterAssistance) {
    let bpromise = butter.content
      .retrieve(['voter_assistance'])
      .then((res) => {
        store.commit('saveButterVoterAssistance', res.data.data.voter_assistance)
        console.log(prefix + ' retrieved: voter_assistance')
      })
      .catch((err) => {
        console.log('error retrieving: voter_assistance ' + err.message)
      })
    promiseArray.push(bpromise)
  } else {
    console.log(prefix + ' already retrieved: voter_assistance')
  }

  if (promiseArray.length) {
    await Promise.all(promiseArray)
  }

}

function getA11yDictionary(dict, lang, isPrefixed) {
  /**
   * Extract the a11y dictionary from the values retrieved from the Butter collection.
   * The a11y dictionary key-value pair is embedded in the retrieved Butter value.
   */

  let a11yDictionary = {}

  const s2 = getDictionary(dict, lang, 'a11y', isPrefixed)
  const s3 = Object.entries(s2)
  s3.forEach(element => {
    let value = element[1]
    if (value && value.includes(":::")) {
      let [phrase, definition] = value.split(':::')
      phrase = phrase.trim()
      definition = definition.trim()

      /* For revealing the Butter dictionary-item prefix
         The prefix is contained in square brackets at the beginning of the phrase.
         Remove the prefix from the phrase and prepend the prefix to the definition.
         We don't want the prefix to remain in the phrase because that will cause the
         dictionary lookup to fail. 
      */
      if (isPrefixed) {
        const indexOfBracket = phrase.indexOf(']')
        const prefix = phrase.slice(0, indexOfBracket + 1)
        phrase = phrase.slice(indexOfBracket + 1)
        definition = prefix + definition
      }

      a11yDictionary[phrase] = definition
    }
  })

  return a11yDictionary
}

function getBanners(banners, lang, isProductionSite) {
  /**
   * Construct an array of banner contents for the VFA Landing Page.
   * Banner appearance is time sensitive; it appears on the landing page in the time
   * interval as set in Butter.
   */
  if (!banners || (banners && banners.length == 0)) {
    return []
  }

  let bannerArray = []
  let today = getNewVfaDate().getTime()

  for (let ii = 0; ii < banners.length; ii++) {
    if (!banners[ii].start_date || !banners[ii].end_date || !banners[ii]['contents_' + lang]) {
      continue
    }
    if (isProductionSite && banners[ii].id.toLowerCase().indexOf("test") >= 0) {
      continue
    }

    let start_date = new Date(banners[ii].start_date).getTime()
    let end_date = new Date(banners[ii].end_date).getTime()

    if (start_date <= today && today <= end_date) {
      bannerArray.push(banners[ii]['contents_' + lang])
    }
  }

  return bannerArray
}

function getChosenState(store, route) {
  let rps = route.params.state
  let sss = store.state.selectedState
  let lss = ''
  let lsString = localStorage.getItem('localstoragevotingstate')
  if (lsString) {
    let lsObj = JSON.parse(lsString)
    let isAlive = (getNewVfaDate().getTime() < lsObj.expiryTime)
    if (isAlive) {
      lss = lsObj.selectedState
    }
  }

  return (rps ? rps : (sss ? sss : (lss ? lss : ''))).toUpperCase()
}

function getDictionary(dict, lang, dictName, isPrefixed) {
  /*
    Reformats the response from an API call to ButterCMS to get collection containing dictionary items.
    The items contain several fields.  So, the response is a 2-level object: level 1 is the item;
    level 2 is the dictionary item field.
    This function merges all the dictionary items into one 1-level object.

    We use the value of process.env.url to select dictionary items targeted specifically for
    the running website environment.
    The process.env.url contains the protocol as well as the domain e.g. http://localhost:3000
    We use only the domain.
  */
  const s1 = dict
  const s2 = {}
  const k = Object.keys(s1)
  const domain = process.env.url.split('//')[1] // all we need is the current website's domain

  for (let ii = 0; ii < k.length; ii++) {
    const sid = s1[ii].id
    const m = Object.keys(s1[ii]) // gets the items - second level
    for (let jj = 0; jj < m.length; jj++) {
      const g = m[jj].slice(0, m[jj].length - 5) // remove the language suffix
      s2[sid + g] = (!isPrefixed) ? s1[ii][g + '_' + lang] : '[' + dictName + '.' + sid + '.' + g + '_' + lang + ']' + s1[ii][g + '_' + lang]
    }
  }

  /**
   * Here we look for dictionary items targeted for the website (environment).
   * We do this after the for-loop above because we are not sure Butter contains the targeted
   * items after the generic ones.  That is, in the above for-loop we can't be sure we will
   * be overwriting the generic values with the targeted ones.
   *
   * Note: We can only process the entire item i.e. all the fields in the item.  We did not
   * define finer granularity in the schema.
   */
  for (let ii = 0; ii < k.length; ii++) {
    if (!s1[ii].id.includes('@' + domain)) {
      continue
    }
    const sid = s1[ii].id.split('@')[0] // get the root of the id
    const m = Object.keys(s1[ii]) // gets the items - second level
    for (let jj = 0; jj < m.length; jj++) {
      const g = m[jj].slice(0, m[jj].length - 5) // remove the language suffix
      s2[sid + g] = (!isPrefixed) ? s1[ii][g + '_' + lang] : '[' + dictName + '.' + sid + '.' + g + '_' + lang + ']' + s1[ii][g + '_' + lang]
    }
  }

  return s2
}

function getStateRules(rules, lang, isPrefixed) {
  /*
    Reformats the response from an API call to ButterCMS to get collection containing
    state voting rules.  Reformat matches the "old" VFA format.
    Data are defined by the state_voting_rules collection in ButterCMS.
    Prefix 'svr' stands for State Voting Rules.
    This makes it easy to find the key in the rules.
  */
  if (!rules) {
    return null
  }

  let s
  let si = {}
  const scr1 = rules
  const scr2 = {} // holds state voting rules formatted per pre-2022 VFA data object.
  scr2.name = scr1.stateid.slice(3)
  scr2.title = scr2.name
  scr2.iso = scr1.stateid.slice(0, 2)
  scr2.allowsNeverResided = scr1.allowsneverresided
  scr2.id = scr1.id ? scr1.id.replace(/ssn/g, 'SSN').split('-or-') : []
  scr2.id_when_recballot_email = scr1.id_when_recballot_email ? scr1.id_when_recballot_email.replace(/ssn/g, 'SSN').split('-or-') : []
  s = scr1['id_when_recballot_email_tooltip_' + lang]
  scr2.id_when_recballot_email_tooltip = !(isPrefixed && s) ? s : '[svr: id_when_recballot_email_tooltip_' + lang + ']' + s

  scr2.partyPrimary = scr1.partyprimary
  scr2.alternate_name_democratic = scr1.alternate_name_democratic
  scr2.alternate_name_republican = scr1.alternate_name_republican

  scr2.deliveryMethodRegistration = scr1.deliverymethodregistration
  s = scr1['deliverymethodregistrationnote_' + lang]
  scr2.deliveryMethodRegistrationNote = !(isPrefixed && s) ? s : '[svr: deliverymethodregistrationnote_' + lang + ']' + s

  scr2.deliveryMethodBallotRequest = scr1.deliverymethodballotrequest
  s = scr1['deliverymethodballotrequestnote_' + lang]
  scr2.deliveryMethodBallotRequestNote = !(isPrefixed && s) ? s : '[svr: deliverymethodballotrequestnote_' + lang + ']' + s

  scr2.deliveryMethodBallotReturn = scr1.deliverymethodballotreturn
  s = scr1['deliverymethodballotreturnnote_' + lang]
  scr2.deliveryMethodBallotReturnNote = !(isPrefixed && s) ? s : '[svr: deliverymethodballotreturnnote_' + lang + ']' + s

  scr2.ballotReceiptOptions = getDeliveryMethods(scr1.ballotreceiptoptions)
  scr2.allowsscannedsignature = scr1.allowsscannedsignature
  scr2.fpcaSubmitOptionsRegister = getDeliveryMethods(scr1.fpcasubmitoptionsregister)
  scr2.fpcaSubmitOptionsRequest = getDeliveryMethods(scr1.fpcasubmitoptionsrequest)
  scr2.canRegisterWithFwab = scr1.canregisterwithfwab
  scr2.fwabSubmitOptions = getDeliveryMethods(scr1.fwabsubmitoptions)
  scr2.amIRegistered = scr1.amiregistered
  scr2.whereIsMyBallot = scr1.whereismyballot
  scr2.trackYourBallot = scr1.trackyourballot
  scr2.sampleBallot = scr1.sampleballot
  scr2.uocavaVoters = scr1.uocavavoters
  scr2.uocavaVoters2 = scr1.uocavavoters2
  scr2.findmypollingplace = scr1.findmypollingplace
  scr2.militaryseparate = scr1.militaryseparate
  scr2.votertypesused = scr1.votertypesused
  scr2.usesvoterisregistered = scr1.usesvoterisregistered

  s = scr1['partyinformation_' + lang]
  scr2.partyinformation = !(isPrefixed && s) ? s : '[svr: partyinformation_' + lang + ']' + s

  s = scr1['body_' + lang]
  scr2.body = !(isPrefixed && s) ? s : '[svr: body_' + lang + ']' + s

  s = scr1['body2_' + lang]
  scr2.body2 = !(isPrefixed && s) ? s : '[svr: body2_' + lang + ']' + s

  s = scr1['sr1label_' + lang]
  scr2.sr1label = !(isPrefixed && s) ? s : '[svr: sr1label_' + lang + ']' + s

  s = scr1['sr1instructions_' + lang]
  scr2.sr1instructions = !(isPrefixed && s) ? s : '[svr: sr1instructions_' + lang + ']' + s

  s = scr1['sr1tooltip_' + lang]
  scr2.sr1tooltip = !(isPrefixed && s) ? s : '[svr: sr1tooltip_' + lang + ']' + s

  s = scr1['sr2label_' + lang]
  scr2.sr2label = !(isPrefixed && s) ? s : '[svr: sr2label_' + lang + ']' + s

  s = scr1['sr2instructions_' + lang]
  scr2.sr2instructions = !(isPrefixed && s) ? s : '[svr: sr2instructions_' + lang + ']' + s

  s = scr1['sr2tooltip_' + lang]
  scr2.sr2tooltip = !(isPrefixed && s) ? s : '[svr: sr2tooltip_' + lang + ']' + s

  scr2.suppress_si1default = scr1.suppress_si1default
  s = scr1['si1body_' + lang]
  scr2.si1body = !(isPrefixed && s) ? s : '[svr: si1body_' + lang + ']' + s

  scr2.suppress_si2default = scr1.suppress_si2default
  s = scr1['si2body_' + lang]
  scr2.si2body = !(isPrefixed && s) ? s : '[svr: si2body_' + lang + ']' + s

  scr2.suppress_si3default = scr1.suppress_si3default
  s = scr1['si3body_' + lang]
  scr2.si3body = !(isPrefixed && s) ? s : '[svr: si3body_' + lang + ']' + s

  scr2.suppress_si4default = scr1.suppress_si4default
  s = scr1['si4body_' + lang]
  scr2.si4body = !(isPrefixed && s) ? s : '[svr: si4body_' + lang + ']' + s

  // 2023-03-10 At this date there is no suppress_si5default in Butter.
  s = scr1['si5body_' + lang]
  scr2.si5body = !(isPrefixed && s) ? s : '[svr: si5body_' + lang + ']' + s

  scr2.specialinput = []

  si.isenabled = scr1.specialinput1_isenabled
  si.attachment_is_required = scr1.specialinput1_attachment_is_required
  si.is_always_triggered = scr1.specialinput1_is_always_triggered
  si.registration_trigger = scr1.specialinput1_registration_trigger
  si.abroad_trigger = scr1.specialinput1_abroad_trigger
  si.party_trigger = scr1.specialinput1_party_trigger
  si.identification_trigger = scr1.specialinput1_identification_trigger

  s = scr1['specialinput1_label_' + lang]
  si.label = !(isPrefixed && s) ? s : '[svr: specialinput1_label_' + lang + ']' + s

  s = scr1['specialinput1_tooltip_' + lang]
  si.tooltip = !(isPrefixed && s) ? s : '[svr: specialinput1_tooltip_' + lang + ']' + s

  s = scr1['specialinput1_instructions_' + lang]
  si.instructions = !(isPrefixed && s) ? s : '[svr: specialinput1_instructions_' + lang + ']' + s

  s = scr1['specialinput1_voter_reply_label_' + lang]
  si.voter_reply_label = !(isPrefixed && s) ? s : '[svr: specialinput1_voter_reply_label_' + lang + ']' + s

  s = scr1['specialinput1_voter_reply_' + lang]
  si.voter_reply = !(isPrefixed && s) ? s : '[svr: specialinput1_voter_reply_' + lang + ']' + s
  scr2.specialinput.push(JSON.parse(JSON.stringify(si)))

  si.isenabled = scr1.specialinput2_isenabled
  si.attachment_is_required = scr1.specialinput2_attachment_is_required
  si.is_always_triggered = scr1.specialinput2_is_always_triggered
  si.registration_trigger = scr1.specialinput2_registration_trigger
  si.abroad_trigger = scr1.specialinput2_abroad_trigger
  si.party_trigger = scr1.specialinput2_party_trigger
  si.identification_trigger = scr1.specialinput2_identification_trigger

  s = scr1['specialinput2_label_' + lang]
  si.label = !(isPrefixed && s) ? s : '[svr: specialinput2_label_' + lang + ']' + s

  s = scr1['specialinput2_tooltip_' + lang]
  si.tooltip = !(isPrefixed && s) ? s : '[svr: specialinput2_tooltip_' + lang + ']' + s

  s = scr1['specialinput2_instructions_' + lang]
  si.instructions = !(isPrefixed && s) ? s : '[svr: specialinput2_instructions_' + lang + ']' + s

  s = scr1['specialinput2_voter_reply_label_' + lang]
  si.voter_reply_label = !(isPrefixed && s) ? s : '[svr: specialinput2_voter_reply_label_' + lang + ']' + s

  s = scr1['specialinput2_voter_reply_' + lang]
  si.voter_reply = !(isPrefixed && s) ? s : '[svr: specialinput2_voter_reply_' + lang + ']' + s
  scr2.specialinput.push(JSON.parse(JSON.stringify(si)))

  si.isenabled = scr1.specialinput3_isenabled
  si.attachment_is_required = scr1.specialinput3_attachment_is_required
  si.is_always_triggered = scr1.specialinput3_is_always_triggered
  si.registration_trigger = scr1.specialinput3_registration_trigger
  si.abroad_trigger = scr1.specialinput3_abroad_trigger
  si.party_trigger = scr1.specialinput3_party_trigger
  si.identification_trigger = scr1.specialinput3_identification_trigger

  s = scr1['specialinput3_label_' + lang]
  si.label = !(isPrefixed && s) ? s : '[svr: specialinput3_label_' + lang + ']' + s

  s = scr1['specialinput3_tooltip_' + lang]
  si.tooltip = !(isPrefixed && s) ? s : '[svr: specialinput3_tooltip_' + lang + ']' + s

  s = scr1['specialinput3_instructions_' + lang]
  si.instructions = !(isPrefixed && s) ? s : '[svr: specialinput3_instructions_' + lang + ']' + s

  s = scr1['specialinput3_voter_reply_label_' + lang]
  si.voter_reply_label = !(isPrefixed && s) ? s : '[svr: specialinput3_voter_reply_label_' + lang + ']' + s

  s = scr1['specialinput3_voter_reply_' + lang]
  si.voter_reply = !(isPrefixed && s) ? s : '[svr: specialinput3_voter_reply_' + lang + ']' + s
  scr2.specialinput.push(JSON.parse(JSON.stringify(si)))

  s = scr1['dashboardspecial_' + lang]
  scr2.dashboardspecial = !(isPrefixed && s) ? s : '[svr: dashboardspecial_' + lang + ']' + s

  s = scr1['dashboardspecialdeadline_' + lang]
  scr2.dashboardspecialdeadline = !(isPrefixed && s) ? s : '[svr: dashboardspecialdeadline_' + lang + ']' + s

  s = scr1['issues1_label_' + lang]
  scr2.issues1Label = !(isPrefixed && s) ? s : '[svr: issues1_label_' + lang + ']' + s

  s = scr1['issues1_' + lang]
  scr2.issues1 = !(isPrefixed && s) ? s : '[svr: issues1_' + lang + ']' + s

  s = scr1['dashboardspecialnextstep_' + lang]
  scr2.dashboardspecialnextstep = !(isPrefixed && s) ? s : '[svr: dashboardspecialnextstep_' + lang + ']' + s

  s = scr1['fpcasubmitnotes_label_' + lang]
  scr2.fpcasubmitnotes_label = !(isPrefixed && s) ? s : '[svr: fpcasubmitnotes_label_' + lang + ']' + s

  s = scr1['fpcasubmitnotes_body_' + lang]
  scr2.fpcasubmitnotes_body = !(isPrefixed && s) ? s : '[svr: fpcasubmitnotes_body_' + lang + ']' + s

  s = scr1['overseas_status_uncertain_return_' + lang]
  scr2.overseas_status_uncertain_return = !(isPrefixed && s) ? s : '[svr: overseas_status_uncertain_return_' + lang + ']' + s

  s = scr1['overseas_status_intend_to_return_' + lang]
  scr2.overseas_status_intend_to_return = !(isPrefixed && s) ? s : '[svr: overseas_status_intend_to_return_' + lang + ']' + s

  s = scr1['overseas_status_never_resided_in_us_' + lang]
  scr2.overseas_status_never_resided_in_us = !(isPrefixed && s) ? s : '[svr: overseas_status_never_resided_in_us_' + lang + ']' + s

  s = scr1['overseas_status_military_active_duty_' + lang]
  scr2.overseas_status_military_active_duty = !(isPrefixed && s) ? s : '[svr: overseas_status_military_active_duty_' + lang + ']' + s

  s = scr1['overseas_status_military_spouse_' + lang]
  scr2.overseas_status_military_spouse = !(isPrefixed && s) ? s : '[svr: overseas_status_military_spouse_' + lang + ']' + s

  s = scr1.unregistered_voter_submit_options === '' ? 'notused' : scr1.unregistered_voter_submit_options
  scr2.unregistered_voter_submit_options = s.split('-or-')

  scr2.allow_photo_signature_even_though_reject_emailnow = scr1.allow_photo_signature_even_though_reject_emailnow

  scr2.gender_is_required = scr1.gender_is_required

  s = scr1.gender_options === '' ? 'notused' : scr1.gender_options
  scr2.gender_options = s.split('-')

  return scr2
}

function getStateRulesFieldDescriptions(descriptions, lang, isPrefixed) {
  let s
  const scr1 = descriptions
  const scr2 = {}

  scr2.fields_to_display = scr1.fields_to_display

  s = scr1['stateid_' + lang]
  scr2.stateid = !(isPrefixed && s) ? s : '[svrfd: stateid_' + lang + ']' + s

  s = scr1['allowsneverresided_' + lang]
  scr2.allowsneverresided = !(isPrefixed && s) ? s : '[svrfd: allowsneverresided_' + lang + ']' + s

  s = scr1['id_' + lang]
  scr2.id = !(isPrefixed && s) ? s : '[svrfd: id_' + lang + ']' + s

  s = scr1['partyprimary_' + lang]
  scr2.partyprimary = !(isPrefixed && s) ? s : '[svrfd: partyprimary_' + lang + ']' + s

  s = scr1['deliverymethodregistration_' + lang]
  scr2.deliverymethodregistration = !(isPrefixed && s) ? s : '[svrfd: deliverymethodregistration_' + lang + ']' + s

  s = scr1['deliverymethodregistrationnote_' + lang]
  scr2.deliverymethodregistrationnote = !(isPrefixed && s) ? s : '[svrfd: deliverymethodregistrationnote_' + lang + ']' + s

  s = scr1['deliverymethodballotrequest_' + lang]
  scr2.deliverymethodballotrequest = !(isPrefixed && s) ? s : '[svrfd: deliverymethodballotrequest_' + lang + ']' + s

  s = scr1['deliverymethodballotrequestnote_' + lang]
  scr2.deliverymethodballotrequestnote = !(isPrefixed && s) ? s : '[svrfd: deliverymethodballotrequestnote_' + lang + ']' + s

  s = scr1['deliverymethodballotreturn_' + lang]
  scr2.deliverymethodballotreturn = !(isPrefixed && s) ? s : '[svrfd: deliverymethodballotreturn_' + lang + ']' + s

  s = scr1['deliverymethodballotreturnnote_' + lang]
  scr2.deliverymethodballotreturnnote = !(isPrefixed && s) ? s : '[svrfd: deliverymethodballotreturnnote_' + lang + ']' + s

  s = scr1['ballotreceiptoptions_' + lang]
  scr2.ballotreceiptoptions = !(isPrefixed && s) ? s : '[svrfd: ballotreceiptoptions_' + lang + ']' + s

  s = scr1['allowsscannedsignature_' + lang]
  scr2.allowsscannedsignature = !(isPrefixed && s) ? s : '[svrfd: allowsscannedsignature_' + lang + ']' + s

  s = scr1['fpcasubmitoptionsregister_' + lang]
  scr2.fpcasubmitoptionsregister = !(isPrefixed && s) ? s : '[svrfd: fpcasubmitoptionsregister_' + lang + ']' + s

  s = scr1['fpcasubmitoptionsrequest_' + lang]
  scr2.fpcasubmitoptionsrequest = !(isPrefixed && s) ? s : '[svrfd: fpcasubmitoptionsrequest_' + lang + ']' + s

  s = scr1['canregisterwithfwab_' + lang]
  scr2.canregisterwithfwab = !(isPrefixed && s) ? s : '[svrfd: canregisterwithfwab_' + lang + ']' + s

  s = scr1['fwabsubmitoptions_' + lang]
  scr2.fwabsubmitoptions = !(isPrefixed && s) ? s : '[svrfd: fwabsubmitoptions_' + lang + ']' + s

  s = scr1['amiregistered_' + lang]
  scr2.amiregistered = !(isPrefixed && s) ? s : '[svrfd: amiregistered_' + lang + ']' + s

  s = scr1['whereismyballot_' + lang]
  scr2.whereismyballot = !(isPrefixed && s) ? s : '[svrfd: whereismyballot_' + lang + ']' + s

  s = scr1['trackyourballot_' + lang]
  scr2.trackyourballot = !(isPrefixed && s) ? s : '[svrfd: trackyourballot_' + lang + ']' + s

  s = scr1['sampleballot_' + lang]
  scr2.sampleballot = !(isPrefixed && s) ? s : '[svrfd: sampleballot_' + lang + ']' + s

  s = scr1['uocavavoters_' + lang]
  scr2.uocavavoters = !(isPrefixed && s) ? s : '[svrfd: uocavavoters_' + lang + ']' + s

  s = scr1['uocavavoters2_' + lang]
  scr2.uocavavoters2 = !(isPrefixed && s) ? s : '[svrfd: uocavavoters2_' + lang + ']' + s

  s = scr1['findmypollingplace_' + lang]
  scr2.findmypollingplace = !(isPrefixed && s) ? s : '[svrfd: findmypollingplace_' + lang + ']' + s

  s = scr1['militaryseparate_' + lang]
  scr2.militaryseparate = !(isPrefixed && s) ? s : '[svrfd: militaryseparate_' + lang + ']' + s

  s = scr1['votertypesused_' + lang]
  scr2.votertypesused = !(isPrefixed && s) ? s : '[svrfd: votertypesused_' + lang + ']' + s

  s = scr1['usesvoterisregistered_' + lang]
  scr2.usesvoterisregistered = !(isPrefixed && s) ? s : '[svrfd: usesvoterisregistered_' + lang + ']' + s

  s = scr1['attachmentisrequired_' + lang]
  scr2.attachmentisrequired = !(isPrefixed && s) ? s : '[svrfd: attachmentisrequired_' + lang + ']' + s

  s = scr1['body_' + lang]
  scr2.body = !(isPrefixed && s) ? s : '[svrfd: body_' + lang + ']' + s

  s = scr1['body2_' + lang]
  scr2.body2 = !(isPrefixed && s) ? s : '[svrfd: body2_' + lang + ']' + s

  s = scr1['sr1label_' + lang]
  scr2.sr1label = !(isPrefixed && s) ? s : '[svrfd: sr1label_' + lang + ']' + s

  s = scr1['sr1tooltip_' + lang]
  scr2.sr1tooltip = !(isPrefixed && s) ? s : '[svrfd: sr1tooltip_' + lang + ']' + s

  s = scr1['sr1instructions_' + lang]
  scr2.sr1instructions = !(isPrefixed && s) ? s : '[svrfd: sr1instructions_' + lang + ']' + s

  s = scr1['sr2label_' + lang]
  scr2.sr2label = !(isPrefixed && s) ? s : '[svrfd: sr2label_' + lang + ']' + s

  s = scr1['sr2tooltip_' + lang]
  scr2.sr2tooltip = !(isPrefixed && s) ? s : '[svrfd: sr2tooltip_' + lang + ']' + s

  s = scr1['sr2instructions_' + lang]
  scr2.sr2instructions = !(isPrefixed && s) ? s : '[svrfd: sr2instructions_' + lang + ']' + s

  s = scr1['si1body_' + lang]
  scr2.si1body = !(isPrefixed && s) ? s : '[svrfd: si1body_' + lang + ']' + s

  s = scr1['si2body_' + lang]
  scr2.si2body = !(isPrefixed && s) ? s : '[svrfd: si2body_' + lang + ']' + s

  s = scr1['si3body_' + lang]
  scr2.si3body = !(isPrefixed && s) ? s : '[svrfd: si3body_' + lang + ']' + s

  s = scr1['si4body_' + lang]
  scr2.si4body = !(isPrefixed && s) ? s : '[svrfd: si4body_' + lang + ']' + s

  s = scr1['si5body_' + lang]
  scr2.si5body = !(isPrefixed && s) ? s : '[svrfd: si5body_' + lang + ']' + s

  s = scr1['issues1_label_' + lang]
  scr2.issues1_label = !(isPrefixed && s) ? s : '[svrfd: issues1_label_' + lang + ']' + s

  s = scr1['issues1_label_' + lang]
  scr2.issues1_label = !(isPrefixed && s) ? s : '[svrfd: issues1_label_' + lang + ']' + s

  s = scr1['specialinput1_isenabled_' + lang]
  scr2.specialinput1_isenabled = !(isPrefixed && s) ? s : '[svrfd: specialinput1_isenabled_' + lang + ']' + s

  s = scr1['specialinput1_attachment_is_required_' + lang]
  scr2.specialinput1_attachment_is_required = !(isPrefixed && s) ? s : '[svrfd: specialinput1_attachment_is_required_' + lang + ']' + s

  s = scr1['specialinput1_is_always_triggered_' + lang]
  scr2.specialinput1_is_always_triggered = !(isPrefixed && s) ? s : '[svrfd: specialinput1_is_always_triggered_' + lang + ']' + s

  s = scr1['specialinput1_registration_trigger_' + lang]
  scr2.specialinput1_registration_trigger = !(isPrefixed && s) ? s : '[svrfd: specialinput1_registration_trigger_' + lang + ']' + s

  s = scr1['specialinput1_abroad_trigger_' + lang]
  scr2.specialinput1_abroad_trigger = !(isPrefixed && s) ? s : '[svrfd: specialinput1_abroad_trigger_' + lang + ']' + s

  s = scr1['specialinput1_party_trigger_' + lang]
  scr2.specialinput1_party_trigger = !(isPrefixed && s) ? s : '[svrfd: specialinput1_party_trigger_' + lang + ']' + s

  s = scr1['specialinput1_identification_trigger_' + lang]
  scr2.specialinput1_identification_trigger = !(isPrefixed && s) ? s : '[svrfd: specialinput1_identification_trigger_' + lang + ']' + s

  s = scr1['specialinput1_label_' + lang]
  scr2.specialinput1_label = !(isPrefixed && s) ? s : '[svrfd: specialinput1_label_' + lang + ']' + s

  s = scr1['specialinput1_instructions_' + lang]
  scr2.specialinput1_instructions = !(isPrefixed && s) ? s : '[svrfd: specialinput1_instructions_' + lang + ']' + s

  s = scr1['specialinput1_voter_reply_' + lang]
  scr2.specialinput1_voter_reply = !(isPrefixed && s) ? s : '[svrfd: specialinput1_voter_reply_' + lang + ']' + s

  s = scr1['specialinput1_tooltip_' + lang]
  scr2.specialinput1_tooltip = !(isPrefixed && s) ? s : '[svrfd: specialinput1_tooltip_' + lang + ']' + s

  s = scr1['specialinput1_voter_reply_label_' + lang]
  scr2.specialinput1_voter_reply_label = !(isPrefixed && s) ? s : '[svrfd: specialinput1_voter_reply_label_' + lang + ']' + s

  s = scr1['specialinput2_isenabled_' + lang]
  scr2.specialinput2_isenabled = !(isPrefixed && s) ? s : '[svrfd: specialinput2_isenabled_' + lang + ']' + s

  s = scr1['specialinput2_attachment_is_required_' + lang]
  scr2.specialinput2_attachment_is_required = !(isPrefixed && s) ? s : '[svrfd: specialinput2_attachment_is_required_' + lang + ']' + s

  s = scr1['specialinput2_is_always_triggered_' + lang]
  scr2.specialinput2_is_always_triggered = !(isPrefixed && s) ? s : '[svrfd: specialinput2_is_always_triggered_' + lang + ']' + s

  s = scr1['specialinput2_registration_trigger_' + lang]
  scr2.specialinput2_registration_trigger = !(isPrefixed && s) ? s : '[svrfd: specialinput2_registration_trigger_' + lang + ']' + s

  s = scr1['specialinput2_abroad_trigger_' + lang]
  scr2.specialinput2_abroad_trigger = !(isPrefixed && s) ? s : '[svrfd: specialinput2_abroad_trigger_' + lang + ']' + s

  s = scr1['specialinput2_party_trigger_' + lang]
  scr2.specialinput2_party_trigger = !(isPrefixed && s) ? s : '[svrfd: specialinput2_party_trigger_' + lang + ']' + s

  s = scr1['specialinput2_identification_trigger_' + lang]
  scr2.specialinput2_identification_trigger = !(isPrefixed && s) ? s : '[svrfd: specialinput2_identification_trigger_' + lang + ']' + s

  s = scr1['specialinput2_label_' + lang]
  scr2.specialinput2_label = !(isPrefixed && s) ? s : '[svrfd: specialinput2_label_' + lang + ']' + s

  s = scr1['specialinput2_instructions_' + lang]
  scr2.specialinput2_instructions = !(isPrefixed && s) ? s : '[svrfd: specialinput2_instructions_' + lang + ']' + s

  s = scr1['specialinput2_voter_reply_' + lang]
  scr2.specialinput2_voter_reply = !(isPrefixed && s) ? s : '[svrfd: specialinput2_voter_reply_' + lang + ']' + s

  s = scr1['specialinput2_tooltip_' + lang]
  scr2.specialinput2_tooltip = !(isPrefixed && s) ? s : '[svrfd: specialinput2_tooltip_' + lang + ']' + s

  s = scr1['specialinput2_voter_reply_label_' + lang]
  scr2.specialinput2_voter_reply_label = !(isPrefixed && s) ? s : '[svrfd: specialinput2_voter_reply_label_' + lang + ']' + s

  s = scr1['specialinput3_isenabled_' + lang]
  scr2.specialinput3_isenabled = !(isPrefixed && s) ? s : '[svrfd: specialinput3_isenabled_' + lang + ']' + s

  s = scr1['specialinput3_attachment_is_required_' + lang]
  scr2.specialinput3_attachment_is_required = !(isPrefixed && s) ? s : '[svrfd: specialinput3_attachment_is_required_' + lang + ']' + s

  s = scr1['specialinput3_is_always_triggered_' + lang]
  scr2.specialinput3_is_always_triggered = !(isPrefixed && s) ? s : '[svrfd: specialinput3_is_always_triggered_' + lang + ']' + s

  s = scr1['specialinput3_registration_trigger_' + lang]
  scr2.specialinput3_registration_trigger = !(isPrefixed && s) ? s : '[svrfd: specialinput3_registration_trigger_' + lang + ']' + s

  s = scr1['specialinput3_abroad_trigger_' + lang]
  scr2.specialinput3_abroad_trigger = !(isPrefixed && s) ? s : '[svrfd: specialinput3_abroad_trigger_' + lang + ']' + s

  s = scr1['specialinput3_party_trigger_' + lang]
  scr2.specialinput3_party_trigger = !(isPrefixed && s) ? s : '[svrfd: specialinput3_party_trigger_' + lang + ']' + s

  s = scr1['specialinput3_identification_trigger_' + lang]
  scr2.specialinput3_identification_trigger = !(isPrefixed && s) ? s : '[svrfd: specialinput3_identification_trigger_' + lang + ']' + s

  s = scr1['specialinput3_label_' + lang]
  scr2.specialinput3_label = !(isPrefixed && s) ? s : '[svrfd: specialinput3_label_' + lang + ']' + s

  s = scr1['specialinput3_instructions_' + lang]
  scr2.specialinput3_instructions = !(isPrefixed && s) ? s : '[svrfd: specialinput3_instructions_' + lang + ']' + s

  s = scr1['specialinput3_voter_reply_' + lang]
  scr2.specialinput3_voter_reply = !(isPrefixed && s) ? s : '[svrfd: specialinput3_voter_reply_' + lang + ']' + s

  s = scr1['specialinput3_tooltip_' + lang]
  scr2.specialinput3_tooltip = !(isPrefixed && s) ? s : '[svrfd: specialinput3_tooltip_' + lang + ']' + s

  s = scr1['specialinput3_voter_reply_label_' + lang]
  scr2.specialinput3_voter_reply_label = !(isPrefixed && s) ? s : '[svrfd: specialinput3_voter_reply_label_' + lang + ']' + s

  s = scr1['dashboardspecial_' + lang]
  scr2.dashboardspecial = !(isPrefixed && s) ? s : '[svrfd: dashboardspecial_' + lang + ']' + s

  s = scr1['dashboardspecialdeadline_' + lang]
  scr2.dashboardspecialdeadline = !(isPrefixed && s) ? s : '[svrfd: dashboardspecialdeadline_' + lang + ']' + s

  s = scr1['dashboardspecialnextstep_' + lang]
  scr2.dashboardspecialnextstep = !(isPrefixed && s) ? s : '[svrfd: dashboardspecialnextstep_' + lang + ']' + s

  s = scr1['fpcasubmitnotes_label_' + lang]
  scr2.fpcasubmitnotes_label = !(isPrefixed && s) ? s : '[svrfd: fpcasubmitnotes_label_' + lang + ']' + s

  s = scr1['fpcasubmitnotes_label_' + lang]
  scr2.fpcasubmitnotes_label = !(isPrefixed && s) ? s : '[svrfd: fpcasubmitnotes_label_' + lang + ']' + s

  s = scr1['overseas_status_uncertain_return_' + lang]
  scr2.overseas_status_uncertain_return = !(isPrefixed && s) ? s : '[svrfd: overseas_status_uncertain_return_' + lang + ']' + s

  s = scr1['overseas_status_intend_to_return_' + lang]
  scr2.overseas_status_intend_to_return = !(isPrefixed && s) ? s : '[svrfd: overseas_status_intend_to_return_' + lang + ']' + s

  s = scr1['overseas_status_never_resided_in_us_' + lang]
  scr2.overseas_status_never_resided_in_us = !(isPrefixed && s) ? s : '[svrfd: overseas_status_never_resided_in_us_' + lang + ']' + s

  return scr2
}

function getElections(butterElections, FWAB_TRIGGER_DEFAULT, lang) {
  /*
    Reformats the response from an API call to ButterCMS to get collection containing state elections.
    Reformat matches the "old" VFA format except "notes" contains the fulltext of the note.
    The old VFA "note" contained only a reference code (state_ISO-code + index_number) to the text.
    The reference code was translated by the appropriate language JSON in /lang/ *.json.
    butterElections contains the elections for the selected US state;
    hence, there is no need to pass the US state as a parameter
  */
  if (!butterElections.length) {
    return []
  }

  const tasks = ['registration', 'ballotrequest', 'ballotreturn']
  const taskParams = ['votertype', 'voterisregistered', 'rule', 'date', 'note']

  const scr1 = butterElections
  const scr2 = [] // holds state elections formatted per old VFA data object.  we return this new array.
  let scr4 // scratch object to hold one item from the Butter collection

  for (let kk = 0; kk < scr1.length; kk++) {
    let vv

    scr4 = scr1[kk]
    const allKeys = Object.keys(scr4)
    const scr3 = {} // scratch object to hold one, newly "old-formatted" election
    scr3.state = scr4.stateid.slice(0, 2)
    scr3.date = scr4.date.replace("T00:00:00", "T23:59:59")

    let ft = scr4.fwab_trigger ? scr4.fwab_trigger : FWAB_TRIGGER_DEFAULT
    scr3.fwab_trigger = (ft >= 0) ? ft : FWAB_TRIGGER_DEFAULT

    scr3.time = get12HourTimeFromDateTime(scr3.date)
    scr3.electiontype = scr4.electiontype
    scr3.electionname = scr4.electionname
    scr3.rules = {}

    // populate the "rules" child object
    for (let hh = 0; hh < tasks.length; hh++) {
      const task = tasks[hh]
      scr3.rules[task] = []
      const nvotertype = allKeys.filter(item => item.includes(task)).filter(item => item.includes('votertype')).length
      for (let ii = 1; ii <= nvotertype; ii++) {
        vv = {}
        for (let jj = 0; jj < taskParams.length; jj++) {
          const p = taskParams[jj]
          vv[p] = (p !== 'note')
            ? scr4[task + '_' + ii.toString(10) + '_' + p]
            : scr4[task + '_' + ii.toString(10) + '_' + p + '_' + lang]
          if (p === 'date') {
            vv.date = scr4[task + '_' + ii.toString(10) + '_' + p].replace("T00:00:00", "T23:59:59")
            vv.time = get12HourTimeFromDateTime(vv.date)
          }

          /**
           * 2022-09-25 John Yee
           * treat options "Not Used" and "" as "", which is what is returned when the field is not
           * selected; this allows GOTV team to "unselect" a voter registration option after
           * one has already been selected
           *
           * Butter returns slug "notused" or "ignore", so replace "notused" and "ignore" with ""
           */
          if (p === 'voterisregistered') {
            vv[p] = vv[p].replace('notused', '').replace('ignore', '')
          }
        }
        if (Object.keys(vv).length > 0) {
          scr3.rules[task].push(vv)
        }
      }
    }
    scr2.push(scr3)
  }

  /**
   * return the list of elections in order starting with the earliest
   * and after a threshold stale date, where stale_date = last_year Nov 30 at 00:00:00 UTC
   */
  const today = getNewVfaDate()
  const yyyym1 = today.getFullYear() - 1
  const tmzone = today.getTimezoneOffset()
  const staleDate = new Date(yyyym1, 10, 30, 0, -(parseInt(tmzone)), 0) // UTC timezone = 0

  return scr2
    .sort((a, b) => { return (new Date(a.date).getTime()) - (new Date(b.date).getTime()) })
    .filter(x => (new Date(x.date)).getTime() > staleDate.getTime())
}

function getGPPParameters(store, lang, isPrefixed) {
  const GPPParameters = store.state.butterDAGlobalPresidentialPrimary[0]

  const datetime_timezone = GPPParameters.datetime_timezone
  const registration_datetime_start = GPPParameters.da_gpp_registration_datetime_start
  const registration_datetime_end = GPPParameters.da_gpp_registration_datetime_end
  const ballot_send_date = GPPParameters.da_gpp_ballot_send_date
  const voting_datetime_start = GPPParameters.da_gpp_voting_datetime_start
  const voting_datetime_end = GPPParameters.da_gpp_voting_datetime_end

  const label_000 = GPPParameters['label_' + lang]
  const information_000 = GPPParameters['information_' + lang]
  const question_000 = GPPParameters['question_' + lang]
  const ballot_sending_000 = GPPParameters['ballot_sending_' + lang]

  const label = (!isPrefixed) ? label_000 : '[ DAGPP.label_' + lang + ']' + label_000
  const information = (!isPrefixed) ? information_000 : '[ DAGPP.information_' + lang + ']' + information_000
  const question = (!isPrefixed) ? question_000 : '[ DAGPP.question_' + lang + ']' + question_000
  const ballot_sending = (!isPrefixed) ? ballot_sending_000 : '[ DAGPP.ballot_sending_' + lang + ']' + ballot_sending_000

  return {
    datetime_timezone,
    registration_datetime_start,
    registration_datetime_end,
    ballot_send_date,
    voting_datetime_start,
    voting_datetime_end,
    label,
    information,
    question,
    ballot_sending
  }
}

function getUpcomingElections(elections) {
  if (!elections.length) {
    return []
  }

  const s = elections.filter(x => (new Date(x.date).getTime()) >= getNewVfaDate().getTime()) // old
  // const s = elections.filter(x => (new Date(x.date).getTime())>=(getNewVfaDate().getTime()-28*24*60*60*1000)) // Heidi 2024-11-10 - display election deadlines after election day; so, subtract from getNewVfaDate to go back in time before election day
  if (s.length) {
    // To remove an election from Butter the votertype is set to blank.  Hence, filter out those elections.
    for (let ii = 0; ii < s.length; ii++) {
      s[ii].rules.registration = s[ii].rules.registration.filter(x => x.votertype !== '' && x.votertype !== 'not-selected' && x.votertype !== 'ignore').sort((a, b) => { return (new Date(a.date)) - (new Date(b.date)) })
      s[ii].rules.ballotrequest = s[ii].rules.ballotrequest.filter(x => x.votertype !== '' && x.votertype !== 'not-selected' && x.votertype !== 'ignore').sort((a, b) => { return (new Date(a.date)) - (new Date(b.date)) })
      s[ii].rules.ballotreturn = s[ii].rules.ballotreturn.filter(x => x.votertype !== '' && x.votertype !== 'not-selected' && x.votertype !== 'ignore').sort((a, b) => { return (new Date(a.date)) - (new Date(b.date)) })
    }
    return s
  } else {
    return []
  }
}

function getUpcomingElectionsFiltered(elections, militaryseparate, votertypesused, usesvoterisregistered, voterType, voterIsRegistered) {
  if (!elections.length) {
    return []
  }

  const s = elections.filter(x => (new Date(x.date).getTime()) >= getNewVfaDate().getTime())

  if (s.length) {
    // To remove an election task (registration, etc.) from display set the votertype to 'not-used-selected' or 'ignore' in Butter.  Hence, here we filter out those election tasks.
    for (let ii = 0; ii < s.length; ii++) {
      s[ii].rules.registration = s[ii].rules.registration.filter(x => x.votertype !== '' && x.votertype !== 'not-used-selected' && x.votertype !== 'ignore').sort((a, b) => { return (new Date(a.date)) - (new Date(b.date)) })
      s[ii].rules.ballotrequest = s[ii].rules.ballotrequest.filter(x => x.votertype !== '' && x.votertype !== 'not-used-selected' && x.votertype !== 'ignore').sort((a, b) => { return (new Date(a.date)) - (new Date(b.date)) })
      s[ii].rules.ballotreturn = s[ii].rules.ballotreturn.filter(x => x.votertype !== '' && x.votertype !== 'not-used-selected' && x.votertype !== 'ignore').sort((a, b) => { return (new Date(a.date)) - (new Date(b.date)) })
    }
  } else {
    return []
  }

  if (s.length) {
    if (militaryseparate || (votertypesused && votertypesused !== 'not-used')) {
      for (let ii = 0; ii < s.length; ii++) {
        s[ii].rules.registration = s[ii].rules.registration.filter(x => x.votertype === voterType || voterType === 'all-voters')
        s[ii].rules.ballotrequest = s[ii].rules.ballotrequest.filter(x => x.votertype === voterType || voterType === 'all-voters')
        s[ii].rules.ballotreturn = s[ii].rules.ballotreturn.filter(x => x.votertype === voterType || voterType === 'all-voters')
      }
    }
  } else {
    return []
  }

  if (s.length) {
    if (usesvoterisregistered) {
      for (let ii = 0; ii < s.length; ii++) {
        s[ii].rules.registration = s[ii].rules.registration.filter(x => x.voterisregistered === voterIsRegistered || x.voterisregistered === '')
        s[ii].rules.ballotrequest = s[ii].rules.ballotrequest.filter(x => x.voterisregistered === voterIsRegistered || x.voterisregistered === '')
        s[ii].rules.ballotreturn = s[ii].rules.ballotreturn.filter(x => x.voterisregistered === voterIsRegistered || x.voterisregistered === '')
      }
    }
  } else {
    return []
  }

  return s
}

function getNextElection(upcomingElections) {
  const nElections = upcomingElections.length
  return (nElections) ? upcomingElections[0] : {}
}

function getLastElection(upcomingElections) {
  const nElections = upcomingElections.length
  return (nElections) ? upcomingElections[nElections - 1] : {}
}

/**
 * function getDeadlineElection (upcomingElections, voterIsRegistered)
 *
 * this function returns the first election (called the deadline election) for
 * which the voter is qualified to return her FPCA form to her LEO
 *
 * get the deadline election from the list of upcoming elections
 * the list should be filtered by: election date, voterType, and registration status
 * and sorted in ascending date order
 *
 * cases where the deadline date may be empty:
 * registration is not required
 * ballot request is not required (LEO sends ballot automatically)
 *
 * voter is qualified to return here FPCA if the voter is:
 * (1) registered, then the qualification is
 *     - today is earlier than or equal to the deadline for requesting a ballot
 * (2) not registered, then the qualification is
 *     - today is earlier than or equal to the deadline for registering to vote
 */
function getDeadlineElection(upcomingElections, voterIsRegistered) {
  const nElections = upcomingElections.length

  if (!nElections) {
    return {}
  }

  let today = getNewVfaDate().getTime()
  let deadlineElectionIndex = -1

  // a registered voter has until a future ballotRequest deadline to submit the FPCA
  if (voterIsRegistered === "registered" || voterIsRegistered === "") {
    // look in the ballot request rules
    registeredLoop: {
      for (let ii = 0; ii < upcomingElections.length; ii++) {
        for (let jj = 0; jj < upcomingElections[ii].rules.ballotrequest.length; jj++) {
          if (upcomingElections[ii].rules.ballotrequest[jj].rule !== "not-required") {
            let deadlineDate = new Date(upcomingElections[ii].rules.ballotrequest[jj].date).getTime()

            if (today <= deadlineDate) {
              deadlineElectionIndex = ii
              break registeredLoop
            }
          } else {
            // ballotrequest is not required; use the current election
            deadlineElectionIndex = ii
            break registeredLoop
          }
        }
      }
    }
  }

  // an unregistered voter has until a future registration deadline to submit the FPCA
  // unless registration is not required in which case use the ballot request deadline

  if (voterIsRegistered !== "registered" && voterIsRegistered !== "") {
    // look in the registration rules
    ballotRequestLoop1: {
      for (let ii = 0; ii < upcomingElections.length; ii++) {
        for (let jj = 0; jj < upcomingElections[ii].rules.registration.length; jj++) {
          if (upcomingElections[ii].rules.registration[jj].rule !== "not-required") {
            let deadlineDate = new Date(upcomingElections[ii].rules.registration[jj].date)

            if (today <= deadlineDate) {
              deadlineElectionIndex = ii
              break ballotRequestLoop1
            }
          } else {
            // registration is not required; use the current election
            deadlineElectionIndex = ii
            break ballotRequestLoop1
          }
        }
      }
    }

    if (deadlineElectionIndex < 0) {
      // nothing in the registration rules, so look in the ballot request rules
      ballotRequestLoop2: {
        for (let ii = 0; ii < upcomingElections.length; ii++) {
          for (let jj = 0; jj < upcomingElections[ii].rules.ballotrequest.length; jj++) {
            if (upcomingElections[ii].rules.ballotrequest[jj].rule !== "not-required") {
              let deadlineDate = new Date(upcomingElections[ii].rules.ballotrequest[jj].date)

              if (today <= deadlineDate) {
                deadlineElectionIndex = ii
                break ballotRequestLoop2
              }
            } else {
              // ballotrequest is not required; use the current election
              deadlineElectionIndex = ii
              break ballotRequestLoop2
            }
          }
        }
      }
    }
  }

  return (deadlineElectionIndex > -1) ? upcomingElections[deadlineElectionIndex] : {}
}

function getDeadlines(deadlineElection, voterType, voterIsRegistered) {
  const de = JSON.parse(JSON.stringify(deadlineElection))
  let d = {}

  if (de.date) {
    let registration = de.rules.registration
    let ballotrequest = de.rules.ballotrequest
    let ballotreturn = de.rules.ballotreturn

    registration = registration.filter(x => (x.votertype === voterType || x.votertype === 'all-voters' || voterType === 'all-voters'))
    ballotrequest = ballotrequest.filter(x => (x.votertype === voterType || x.votertype === 'all-voters' || voterType === 'all-voters'))
    ballotreturn = ballotreturn.filter(x => (x.votertype === voterType || x.votertype === 'all-voters' || voterType === 'all-voters'))

    registration = registration.filter(x => (voterIsRegistered === 'not-used' || x.voterisregistered === voterIsRegistered || x.voterisregistered === 'notregistered' || x.voterisregistered === '' || x.rule.includes('not-required')))
    ballotrequest = ballotrequest.filter(x => (voterIsRegistered === 'not-used' || x.voterisregistered === voterIsRegistered || x.voterisregistered === 'notregistered' || x.voterisregistered === '' || x.rule.includes('not-required')))
    ballotreturn = ballotreturn.filter(x => (voterIsRegistered === 'not-used' || x.voterisregistered === voterIsRegistered || x.voterisregistered === 'notregistered' || x.voterisregistered === '' || x.rule.includes('not-required')))

    const today = getNewVfaDate().getTime()

    const MS_PER_DAY = 1000 * 60 * 60 * 24
    for (let ii = 0; ii < registration.length; ii++) {
      registration[ii].daysTo = ((new Date(registration[ii].date).getTime()) - today) / MS_PER_DAY
    }
    for (let ii = 0; ii < ballotrequest.length; ii++) {
      ballotrequest[ii].daysTo = ((new Date(ballotrequest[ii].date).getTime()) - today) / MS_PER_DAY
    }
    for (let ii = 0; ii < ballotreturn.length; ii++) {
      ballotreturn[ii].daysTo = ((new Date(ballotreturn[ii].date).getTime()) - today) / MS_PER_DAY
    }

    const daysToElection = ((new Date(de.date).getTime()) - today) / MS_PER_DAY
    const FWABDate = (new Date((new Date(de.date + "Z").getTime()) - de.fwab_trigger * MS_PER_DAY)).toISOString().slice(0, 19)
    const daysToFWAB = ((new Date(FWABDate).getTime()) - today) / MS_PER_DAY

    d = {
      deadlineElection: {
        date: de.date,
        daysToElection
      },
      FWAB: {
        date: FWABDate,
        daysToFWAB
      },
      registration,
      ballotrequest,
      ballotreturn
    }
  } else {
    d = {
      deadlineElection: {
        date: '',
        daysToElection: 900
      },
      FWAB: {
        date: '',
        daysToFWAB: 900
      },
      registration: [],
      ballotrequest: [],
      ballotreturn: []
    }
  }
  return d
}

function getLang(lang) {
  // Change locale to all lowercase when using Butter because Butter slugs are all lowercase.
  // i18n locales still follow lowercase-uppercase convention.
  let ll = lang.toLowerCase()

  // The legacy VFA site uses two-letter codes for the language locale.
  // We append an additional two-letter code to match the locale codes we implemented in Butter.
  if (ll === 'en') {
    ll = ll + 'us'
  }
  if (ll === 'es') {
    ll = ll + 'es'
  }
  return ll
}

function getDeliveryMethods(deliveryMethods) {
  return deliveryMethods.split('-or-').map(w => w.substring(0, 1).toUpperCase() + w.substring(1))
}

function updateFvapLeosWithButter(fvapLeos, butterLeos) {
  const bu = JSON.parse(JSON.stringify(butterLeos))
  const fv = JSON.parse(JSON.stringify(fvapLeos))

  for (var ii = 0; ii < bu.length; ii++) {
    // get index of the old LEO; use a few selected fields to define "same LEO"
    let LEOindex = fv.findIndex(leo =>
      leo.n.replace(/\s/g, "").toLowerCase() === bu[ii].n_name_old.replace(/\s/g, "").toLowerCase()
      && leo.a1.replace(/\s/g, "").toLowerCase() === bu[ii].a1_address_1_old.replace(/\s/g, "").toLowerCase()
      && leo.a2.replace(/\s/g, "").toLowerCase() === bu[ii].a2_address_2_old.replace(/\s/g, "").toLowerCase()
      && leo.a3.replace(/\s/g, "").toLowerCase() === bu[ii].a3_address_3_old.replace(/\s/g, "").toLowerCase()
      && leo.c.replace(/\s/g, "").toLowerCase() === bu[ii].c_city_old.replace(/\s/g, "").toLowerCase()
      && leo.j.replace(/\s/g, "").toLowerCase() === bu[ii].j_jurisdiction_name_old.replace(/\s/g, "").toLowerCase()
    )

    // if we find the old LEO, then we update it with the new data
    if (LEOindex >= 0) {
      let bbnew = {}
      let allKeys = Object.keys(bu[ii])

      for (var jj = 1; jj < allKeys.length; jj++) {
        let key = allKeys[jj]
        // ignore the old fields
        if (key.indexOf('_old') > -1) {
          continue
        }
        // 'refuses_submissions_by' is a special case that we have to split to get an array of values
        if (key.indexOf('refuses_submissions_by') > -1) {
          continue
        }

        let ke = key.split('_')[0]

        // exceptions; due to ButterCMS uses lowercase
        if (ke === 'fpcaoffice') {
          ke = 'fpcaOffice'
        }
        if (ke === 'fwaboffice') {
          ke = 'fwabOffice'
        }

        /*
          Allow blank-out only fields fax, phone, email, address 2, address 3, ZIP code.
          For all other fields,
            if newvalue is blank,
            then take the value from the original JSON i.e. no change to the field.
          We do not take the "*_old" value from Butter in case that value was entered incorrectly.
          That's an additional safeguard against accidentally modifying data that should not be modified.
        */
        let newvalue = bu[ii][key]
        if ('fpea2a3z'.indexOf(ke) > -1) {
          bbnew[ke] = (newvalue !== '') ? ((newvalue === 'BLANK@BLANK.BLANK' || newvalue === '000-000-0000') ? '' : newvalue) : fv[LEOindex][ke]
        } else {
          bbnew[ke] = (newvalue !== '' && newvalue !== 'BLANK@BLANK.BLANK') ? newvalue : fv[LEOindex][ke]
        }
      }

      bbnew.i = fv[LEOindex].i // use the old index number
      bbnew.d = getNewVfaDate().toISOString()
      bbnew.s = bu[ii].s_state.slice(0, 2).toUpperCase() // only the state ISO code
      bbnew.refuses_submissions_by = bu[ii].refuses_submissions_by ? (bu[ii].refuses_submissions_by.includes("notapplicable") ? [] : bu[ii].refuses_submissions_by.split("-or-")) : []
      bbnew.remove = bu[ii].remove

      fv[LEOindex] = bbnew
    }
  }
  return fv.filter(item => !((Object.keys(item).indexOf("remove") >= 0) && (item.remove === true)))
}

function getStateRegistrationSubmitOptions(stateRules) {
  // Look for a set of submission options (so) in the state rules. Start with registration.
  // If nothing in Registration, then cascade down.
  let so = stateRules.deliveryMethodRegistration.toLowerCase()
  if (!so) {
    so = stateRules.deliveryMethodBallotRequest
  }
  if (!so) {
    so = stateRules.deliveryMethodBallotReturn
  }

  // If still no submission options, then assume voter may submit via postal mail.
  if (!so) {
    return {
      mayEmail: false,
      mayFax: false,
      mayMail: true,
      mayOnline: false
    }
  }

  // guarantee differentiation with between string "email and "postal mail" or "mail"
  let emailReplacementString = "emaiil"
  so = so.replace(/email/g, emailReplacementString)

  return {
    mayEmail: so.indexOf(emailReplacementString) > -1,
    mayFax: so.indexOf('fax') > -1,
    mayMail: so.indexOf('mail') > -1,
    mayOnline: so.indexOf('online') > -1
  }
}

function getStateBallotRequestSubmitOptions(stateRules) {
  // Look for a set of submission options (so) in the state rules. Start with ballot request.
  // If nothing in BallotRequest, then cascade down.
  let so = stateRules.deliveryMethodBallotRequest.toLowerCase()
  if (!so) {
    so = stateRules.deliveryMethodBallotReturn
  }

  // If still no submission options, then assume voter may submit via postal mail.
  if (!so) {
    return {
      mayEmail: false,
      mayFax: false,
      mayMail: true,
      mayOnline: false
    }
  }

  // guarantee differentiation with between string "email and "postal mail" or "mail"
  let emailReplacementString = "emaiil"
  so = so.replace(/email/g, emailReplacementString)

  return {
    mayEmail: so.indexOf(emailReplacementString) > -1,
    mayFax: so.indexOf('fax') > -1,
    mayMail: so.indexOf('mail') > -1,
    mayOnline: so.indexOf('online') > -1
  }
}

function reformatRules(sourceRules, state, date, electiontype, ruleType, butterDictionary) {
  const voterTypeFullName = {
    "all-voters": butterDictionary.I46,
    "civilian": butterDictionary.H24,
    "civilian-intend-to-return": butterDictionary.P01,
    "civilian-uncertain-return": butterDictionary.P02,
    "civilian-never-resided-in-us": butterDictionary.P03,
    "military": butterDictionary.H31,
    "military-active-duty": butterDictionary.H30,
    "military-spouse": butterDictionary.H32,
    "not-used-selected": "Not Used-Selected",
  }

  let bbb = sourceRules
  let ve = []

  for (let jj = 0; jj < bbb.length; jj++) {
    let ve1 = {}

    ve1.state = state
    ve1.electionDate = date
    ve1.electionType = electiontype
    ve1.ruleType = ruleType
    ve1.ruleDate = bbb[jj].date
    ve1.ruleTime = bbb[jj].time
    ve1.rule = ""
    ve1.voterType = []
    ve1.voterTypeFullName = voterTypeFullName[bbb[jj].votertype]

    if (bbb[jj].rule.includes("signed/postmarked")) {
      ve1.rule = "signedPostmarked"
      bbb[jj].rule = bbb[jj].rule.replace(/signed\/postmarked-by/g, "")
    }
    if (bbb[jj].rule.includes("postmarked")) {
      ve1.rule = "postmarkedBy"
      bbb[jj].rule = bbb[jj].rule.replace(/postmarked-by/g, "")
    }
    if (bbb[jj].rule.includes("received")) {
      ve1.rule = "receivedBy"
      bbb[jj].rule = bbb[jj].rule.replace(/received-by/g, "")
    }
    if (bbb[jj].rule.includes("sent")) {
      ve1.rule = "sentBy"
      bbb[jj].rule = bbb[jj].rule.replace(/sent-by/g, "")
    }
    if (bbb[jj].rule.includes("signed")) {
      ve1.rule = "signedBy"
      bbb[jj].rule = bbb[jj].rule.replace(/signed-by/g, "")
    }

    bbb[jj].rule = bbb[jj].rule.replace(/online-upload/g, "online+upload")
    bbb[jj].rule = bbb[jj].rule.replace(/postal-mail/g, "postal+mail")
    bbb[jj].rule = bbb[jj].rule.replace(/-/g, "")
    bbb[jj].rule = bbb[jj].rule.replace(/online\+upload/g, "postal-mail")
    bbb[jj].rule = bbb[jj].rule.replace(/postal\+mail/g, "postal-mail")
    ve1.submissionOptions = bbb[jj].rule.split("or")

    if (bbb[jj].votertype === "all-voters") {
      ve1.voterType = ["Citizen", "Military"]
    }
    if (bbb[jj].votertype.includes("military")) {
      ve1.voterType = ["Military"]
    }
    if (bbb[jj].votertype.includes("civilian")) {
      ve1.voterType = ["Citizen"]
    }

    ve1.note = bbb[jj].note
    ve1.notes = bbb[jj].note ? true : false

    ve.push(ve1)
  }

  return ve
}

function get12HourTimeFromDateTime(dateTimeString) {
  // assume: dateTimeString format YYYY-MM-DDTHH:MM:SS
  let rawhhmm = dateTimeString.toString().trim()
  let hmampm = ""

  if (rawhhmm !== "") {
    let hhmm = rawhhmm.split("T")[1].slice(0, 5)
    let hh = parseInt(hhmm.split(":")[0])
    let mm = hhmm.split(":")[1]
    if (hh > 12) {
      hmampm = (hh - 12).toString(10) + ":" + mm + "PM"
    } else
      if (hh === 12) {
        hmampm = "12:" + mm + "PM"
      } else
        if (hh === 0) {
          hmampm = "12:" + mm + "AM"
        } else {
          hmampm = hh.toString(10) + ":" + mm + "AM"
        }
  }

  return hmampm
}

function getNewVfaDate() {
  return new Date(new Date().getTime() + parseInt(sessionStorage.getItem("timeWarpOffset")))
}

function getWrappedLines(longLine, maxLineLength) {
  if (!longLine.trim()) {
    return []
  }

  let words = longLine.trim().replace(/\s+/g, " ").split(" ")
  let lines = []

  do {
    let line = ''
    do {
      line += words.shift() + ' '
    } while ((line.length < maxLineLength) && (words.length > 0))

    lines.push(line)
  } while (words.length > 0)

  return lines
}

function getValue(key, rawData) {
  /*
    2022-10-25 John Yee
    This is not a generic arithmetic calculator.
    All this does is parse very simple sum expressions from the form template layout fields.
    No subtraction, multiplication, or division.

    Each term must be a number or the key of a numeric value.
    You must put numbers last in a sum.  Do not start an expression with a number.
  */

  let ke = key.trim()
  let v = rawData[ke]

  // "key" is a number and is not a real key; return the number
  if (!v && parseFloat(ke)) {
    return parseFloat(ke)
  }

  // "key" is not a number; parse the corresponding value
  if (parseFloat(v)) {
    return parseFloat(v)
  } else {
    if (v.indexOf("+") > -1) {
      let w = v.split("+")
      let s = 0
      for (let jj = 0; jj < w.length; jj++) {
        s += getValue(w[jj], rawData)
      }
      return s
    } else {
      return getValue(v, rawData)
    }
  }
}

function getDictFLJ(languageKey, butterDictionary) {
  /**
   * 2022-12-06 John Yee
   *
   * function name inspired by: get Dictionary_item From Language JSON
   *
   * This is a bridge between the original language files (in folder lang/ e.g. lang/en-US.json)
   * and the Butter dictionary.
   *
   * The parameter "languageKey" is the key as found in the original language files.
   * The parameter "butterDictionary" is the dictionary in Butter in which to look for the key.
   * The return value is the value of the corresponding dictionary item.
   *
   * With thanks to
   * https://stackoverflow.com/questions/6114210/is-returning-out-of-a-switch-statement-considered-a-better-practice-than-using-b
   *
   * Note: Nullish coalescing operator ?? is not supported in the nuxt-supplied javascript.
   */

  const value = ({
    'addressPart.address': butterDictionary.I36,
    'addressPart.canton': butterDictionary.I37,
    'addressPart.city': butterDictionary.I38,
    'addressPart.complement': butterDictionary.M31,
    'addressPart.department': butterDictionary.I39,
    'addressPart.district': butterDictionary.I40,
    'addressPart.neighborhood': butterDictionary.M32,
    'addressPart.postal': butterDictionary.I41,
    'addressPart.province': butterDictionary.I42,
    'addressPart.state': butterDictionary.I43,
    'addressPart.streetAddress1': butterDictionary.I44,
    'addressPart.streetAddress2': butterDictionary.I45,
    'addressPart.thoroughfare': butterDictionary.M30,

    'election.email-or-fax-or-online-upload-received-by': butterDictionary.J09,
    'election.email-or-fax-received-by': butterDictionary.J10,
    'election.email-or-mail-received-by': butterDictionary.J11,
    'election.email-or-online-upload-received-by': butterDictionary.J12,
    'election.email-received-by': butterDictionary.J13,
    'election.fax-or-mail-received-by': butterDictionary.J14,
    'election.fax-or-online-upload-received-by': butterDictionary.J15,
    'election.fax-received-by': butterDictionary.J16,
    'election.federal-democratic-primary': butterDictionary.J17,
    'election.federal-primary': butterDictionary.J18,
    'election.federal-primary-runoff': butterDictionary.J19,
    'election.federal-republican-primary': butterDictionary.J20,
    'election.general': butterDictionary.J21,
    'election.general-runoff': butterDictionary.J22,
    'election.mail-or-email-or-fax-or-online-upload-received-by': butterDictionary.J23,
    'election.mail-or-email-or-fax-received-by': butterDictionary.J24,
    'election.not-required': butterDictionary.J25,
    'election.online-upload-received-by': butterDictionary.J26,
    'election.postal-mail-postmarked-by': butterDictionary.J27,
    'election.postal-mail-received-by': butterDictionary.J28,
    'election.postal-mail-sent-by': butterDictionary.J29,
    'election.postal-mail-signed-by': butterDictionary.G38,
    'election.presidential-primary': butterDictionary.J30,
    'election.primary': butterDictionary.M44,
    'election.special-general-congressional-district': butterDictionary.J31,
    'election.special-general-runoff': butterDictionary.J32,
    'election.special-general-us-senate': butterDictionary.J33,
    'election.special-primary-congressional-district': butterDictionary.J34,
    'election.special-primary-runoff': butterDictionary.J35,
    'election.special-primary-us-senate': butterDictionary.J36,
    'election.state-democratic-primary': butterDictionary.J37,
    'election.state-general': butterDictionary.J38,
    'election.state-general-runoff': butterDictionary.J39,
    'election.state-primary': butterDictionary.J40,
    'election.state-primary-runoff': butterDictionary.J41,
    'election.state-republican-primary': butterDictionary.J42,
    'election.state-special-general': butterDictionary.J43,
    'election.state-special-primary': butterDictionary.J44,

    'generic.email': butterDictionary.D37,
    'generic.email-or-fax': butterDictionary.J00,
    'generic.email-or-fax-or-mail': butterDictionary.J01,
    'generic.email-or-fax-or-mail-or-online': butterDictionary.J02,
    'generic.email-or-mail': butterDictionary.J03,
    'generic.email-or-online': butterDictionary.J04,
    'generic.fax': butterDictionary.D38,
    'generic.fax-or-mail': butterDictionary.J05,
    'generic.fax-or-online': butterDictionary.J06,
    'generic.mail': butterDictionary.D39,
    'generic.mail-or-online': butterDictionary.J07,
    'generic.online': butterDictionary.J08,

    'request.abrAdr.instructions': butterDictionary.K37,
    'request.abrAdr.label': butterDictionary.K38,
    'request.abrAdr.messages.A-maxLength': butterDictionary.K33,
    'request.abrAdr.messages.A-required': butterDictionary.K34,
    'request.abrAdr.messages.alt1-required': butterDictionary.K35,
    'request.abrAdr.messages.C-required': butterDictionary.K34,
    'request.abrAdr.messages.country-required': butterDictionary.K36,
    'request.abrAdr.messages.countryiso-required': butterDictionary.K36, /** 2023-11-23 John Yee todo: enter messsage in Butter */
    'request.abrAdr.messages.D-required': butterDictionary.K34,
    'request.abrAdr.messages.S-countryState': butterDictionary.K34,
    'request.abrAdr.messages.S-required': butterDictionary.K34,
    'request.abrAdr.messages.US-warning': butterDictionary.E09,
    'request.abrAdr.messages.X-required': butterDictionary.K34,
    'request.abrAdr.messages.Z-required': butterDictionary.K34,
    'request.abrAdr.messages.Z-postalCode': butterDictionary.Q17,
    'request.abrAdr.noAutocomplete': butterDictionary.E10,
    'request.abrAdr.placeholder': butterDictionary.K39,
    'request.abrAdr.selectCountry': butterDictionary.E11,
    'request.abrAdr.structured-label': '',
    'request.abrAdr.tooltip': butterDictionary.K05,
    'request.abrAdr.tooltipTitle': butterDictionary.K06,
    'request.abrAdr.unstructured-label': '',

    'request.altEmail.label': butterDictionary.K40,
    'request.altEmail.messages.didYouMean': butterDictionary.K41,
    'request.altEmail.messages.email': butterDictionary.K42,
    'request.altEmail.messages.required': butterDictionary.K43,

    'request.DaEmail.label': butterDictionary.K28,
    'request.DaEmail.messages.didYouMean': butterDictionary.K41,
    'request.DaEmail.messages.email': butterDictionary.K42,
    'request.DaEmail.messages.required': butterDictionary.L03,

    'request.DaFirstName.messages.maxLength': butterDictionary.K24, /** parameter {max} */
    'request.DaFirstName.messages.required': butterDictionary.K25,

    'request.DaLastName.messages.maxLength': butterDictionary.K24, /** parameter {max} */
    'request.DaLastName.messages.required': butterDictionary.K27,

    'request.DaMiddleName.messages.maxLength': butterDictionary.K24, /** parameter {max} */
    'request.DaMiddleName.messages.required': butterDictionary.Q15,

    'request.DaMobile.label': butterDictionary.K47,
    'request.DaMobile.messages.required': butterDictionary.N42,
    'request.DaMobile.messages.validPhone': butterDictionary.K49,

    'request.DaPhone.label': butterDictionary.K47,
    'request.DaPhone.messages.required': butterDictionary.N41,
    'request.DaPhone.messages.validPhone': butterDictionary.K49,

    'request.DaOptinEmail.messages.required': butterDictionary.N50,
    'request.DaOptinMobile.messages.required': butterDictionary.N50,
    'request.DaOptinPhone.messages.required': butterDictionary.N50,

    'request.deadlineLanguage.documentRequired': butterDictionary.M29,
    'request.deadlineLanguage.email': butterDictionary.D37,
    'request.deadlineLanguage.fax': butterDictionary.D38,
    'request.deadlineLanguage.mail': butterDictionary.D39,
    'request.deadlineLanguage.online': butterDictionary.J08,
    'request.deadlineLanguage.postmarkedBy': butterDictionary.K00,
    'request.deadlineLanguage.receivedBy': butterDictionary.K01,
    'request.deadlineLanguage.sendBallot45days': butterDictionary.L00,
    'request.deadlineLanguage.sendBallotLessThan45days': butterDictionary.L01,
    'request.deadlineLanguage.sentBy': butterDictionary.K02,
    'request.deadlineLanguage.signedBy': butterDictionary.K03,
    'request.deadlineLanguage.signedPostmarked': butterDictionary.K04,

    'request.email.label': butterDictionary.E33,
    'request.email.messages.didYouMean': butterDictionary.E34,
    'request.email.messages.email': butterDictionary.K42,
    'request.email.messages.required': butterDictionary.L03,

    'request.fax.label': butterDictionary.K47,
    'request.fax.messages.required': butterDictionary.K48,
    'request.fax.messages.validPhone': butterDictionary.K49,

    'request.firstName.messages.maxLength': butterDictionary.K24, /** parameter {max} */
    'request.firstName.messages.required': butterDictionary.K25,

    'request.fwdAdr.instructions': butterDictionary.K44,
    'request.fwdAdr.label': butterDictionary.K45,
    'request.fwdAdr.messages.A-maxLength': butterDictionary.K33,
    'request.fwdAdr.messages.A-required': butterDictionary.K34,
    'request.fwdAdr.messages.alt1-required': butterDictionary.K35,
    'request.fwdAdr.messages.C-required': butterDictionary.K34,
    'request.fwdAdr.messages.country-required': butterDictionary.K36,
    'request.fwdAdr.messages.D-required': butterDictionary.K34,
    'request.fwdAdr.messages.S-required': butterDictionary.K34,
    'request.fwdAdr.messages.X-required': butterDictionary.K34,
    'request.fwdAdr.messages.Z-required': butterDictionary.K34,
    'request.fwdAdr.messages.Z-postalCode': butterDictionary.Q17,
    'request.fwdAdr.question': butterDictionary.K46,
    'request.fwdAdr.structured-label': '',
    'request.fwdAdr.tooltip': butterDictionary.K07,
    'request.fwdAdr.tooltipTitle': butterDictionary.K08,
    'request.fwdAdr.unstructured-label': '',

    'request.id.military-id': butterDictionary.K09,
    'request.id.militaryid': butterDictionary.K09,
    'request.id.noid1': butterDictionary.E42,
    'request.id.noid2': butterDictionary.E43,
    'request.id.optional': butterDictionary.K10,
    'request.id.passportnumber': butterDictionary.K11,
    'request.id.SSN': butterDictionary.E45,
    'request.id.ssn': butterDictionary.E45,
    'request.id.SSN4': butterDictionary.E46,
    'request.id.ssn4': butterDictionary.E46,
    'request.id.SSN9': butterDictionary.E47,
    'request.id.ssn9': butterDictionary.E47,
    'request.id.stateid': butterDictionary.E48,
    'request.id.stateterritory': butterDictionary.K12,
    'request.id.stateterritoryid': butterDictionary.K12,
    'request.id.territoryid': butterDictionary.K13,
    'request.id.tribal': butterDictionary.K14,
    'request.id.tribalid': butterDictionary.K14,
    'request.id.us-passport-number': butterDictionary.K11,

    'request.jurisdiction.label': butterDictionary.F12,
    'request.jurisdiction.tooltip': butterDictionary.F16,
    'request.jurisdiction.tooltipTitle': butterDictionary.F17,

    'request.lastName.messages.maxLength': butterDictionary.K24, /** parameter {max} */
    'request.lastName.messages.required': butterDictionary.K27,

    'request.middleName.messages.maxLength': butterDictionary.K24, /** parameter {max} */

    'request.party.democratic': butterDictionary.J45,
    'request.party.independent': butterDictionary.J46,
    'request.party.none': butterDictionary.J47,
    'request.party.other': butterDictionary.F21,
    'request.party.prefernottoanswer': butterDictionary.J48,
    'request.party.republican': butterDictionary.J49,

    'request.previousName.label': butterDictionary.F26,
    'request.previousName.tooltip': butterDictionary.F27,
    'request.previousName.tooltipTitle': butterDictionary.I09,
    'request.previousName.messages.maxLength': butterDictionary.K24, /** parameter {max} */
    'request.previousName.messages.required': butterDictionary.D45,

    'request.recBallot.label': butterDictionary.F30,
    'request.recBallot.tooltip': butterDictionary.F32,
    'request.recBallot.tooltipTitle': butterDictionary.F33,

    'request.sex.label': butterDictionary.F41,
    'request.sex.tooltip': butterDictionary.F43,
    'request.sex.tooltipTitle': butterDictionary.I10,

    'request.stages.stage1': butterDictionary.K30,
    'request.stages.stage2': butterDictionary.K31,
    'request.stages.stage3': butterDictionary.K32,
    'request.stages.stage4': butterDictionary.H10,
    'request.stages.stage5': butterDictionary.H11,

    'request.tel.label': butterDictionary.K50,
    'request.tel.messages.validPhone': butterDictionary.K49,

    'request.votAdr.A': butterDictionary.H14,
    'request.votAdr.B': butterDictionary.H15,
    'request.votAdr.C': butterDictionary.H16,
    'request.votAdr.instructions': butterDictionary.H17,
    'request.votAdr.label': butterDictionary.H18,
    'request.votAdr.messages.A-required': butterDictionary.K15,
    'request.votAdr.messages.C-required': butterDictionary.K16,
    'request.votAdr.messages.S-required': butterDictionary.K17,
    'request.votAdr.messages.Z-required': butterDictionary.K18,
    'request.votAdr.messages.Z-usZip': butterDictionary.K19,
    'request.votAdr.S': butterDictionary.H19,
    'request.votAdr.tooltip': butterDictionary.H20,
    'request.votAdr.tooltipTitle': butterDictionary.H21,
    'request.votAdr.Y': butterDictionary.H22,
    'request.votAdr.Z': butterDictionary.H23,

    'request.voterClass.all-voters': butterDictionary.I46,
    'request.voterClass.allVoters': butterDictionary.I46,
    'request.voterClass.civilian': butterDictionary.C18,
    'request.voterClass.civilianType': butterDictionary.C18,
    'request.voterClass.civilian-intend-to-return': butterDictionary.Q08,
    'request.voterClass.civilian-uncertain-return': butterDictionary.Q09,
    'request.voterClass.civilian-never-resided-in-us': butterDictionary.Q10,
    'request.voterClass.military': butterDictionary.H30,
    'request.voterClass.military-active-duty': butterDictionary.C19,
    'request.voterClass.military-dependent': butterDictionary.H32,
    'request.voterClass.military-spouse': butterDictionary.C20,
    'request.voterClass.militaryType': butterDictionary.H31,
    'request.voterClass.milSpouse': butterDictionary.H32,
    'request.voterClass.neverResided': butterDictionary.H33,
    'request.voterClass.uncertainReturn': butterDictionary.H36,

    'states.AK': butterDictionary.L17,
    'states.AL': butterDictionary.L18,
    'states.AR': butterDictionary.L19,
    'states.AS': butterDictionary.L20,
    'states.AZ': butterDictionary.L21,
    'states.CA': butterDictionary.L22,
    'states.CO': butterDictionary.L23,
    'states.CT': butterDictionary.L24,
    'states.DC': butterDictionary.L25,
    'states.DE': butterDictionary.L26,
    'states.FL': butterDictionary.L27,
    'states.FM': butterDictionary.L28,
    'states.GA': butterDictionary.L29,
    'states.GU': butterDictionary.L30,
    'states.HI': butterDictionary.L31,
    'states.IA': butterDictionary.L32,
    'states.ID': butterDictionary.L33,
    'states.IL': butterDictionary.L34,
    'states.IN': butterDictionary.L35,
    'states.KS': butterDictionary.L36,
    'states.KY': butterDictionary.L37,
    'states.LA': butterDictionary.L38,
    'states.MA': butterDictionary.L39,
    'states.MD': butterDictionary.L40,
    'states.ME': butterDictionary.L41,
    'states.MH': butterDictionary.L42,
    'states.MI': butterDictionary.L43,
    'states.MN': butterDictionary.L44,
    'states.MO': butterDictionary.L45,
    'states.MP': butterDictionary.L46,
    'states.MS': butterDictionary.L47,
    'states.MT': butterDictionary.L48,
    'states.NC': butterDictionary.L49,
    'states.ND': butterDictionary.L50,
    'states.NE': butterDictionary.M00,
    'states.NH': butterDictionary.M01,
    'states.NJ': butterDictionary.M02,
    'states.NM': butterDictionary.M03,
    'states.NV': butterDictionary.M04,
    'states.NY': butterDictionary.M05,
    'states.OH': butterDictionary.M06,
    'states.OK': butterDictionary.M07,
    'states.OR': butterDictionary.M08,
    'states.PA': butterDictionary.M09,
    'states.PR': butterDictionary.M10,
    'states.PW': butterDictionary.M11,
    'states.RI': butterDictionary.M12,
    'states.SC': butterDictionary.M13,
    'states.SD': butterDictionary.M14,
    'states.TN': butterDictionary.M15,
    'states.TX': butterDictionary.M16,
    'states.US': butterDictionary.M17,
    'states.UT': butterDictionary.M18,
    'states.VA': butterDictionary.M19,
    'states.VI': butterDictionary.M20,
    'states.VT': butterDictionary.M21,
    'states.WA': butterDictionary.M22,
    'states.WI': butterDictionary.M23,
    'states.WV': butterDictionary.M24,
    'states.WY': butterDictionary.M25,
    'states.YT': butterDictionary.C43,
    'states.ZR': butterDictionary.M50,

    'voterType.all-voters': butterDictionary.I46,
    'voterType.civilian': butterDictionary.C18,
    'voterType.civilian-intend-to-return': butterDictionary.Q08,
    'voterType.civilian-uncertain-return': butterDictionary.Q09,
    'voterType.civilian-never-resided-in-us': butterDictionary.Q10,
    'voterType.military': butterDictionary.H30,
    'voterType.military-active-duty': butterDictionary.C19,
    'voterType.military-dependent': butterDictionary.H32,
    'voterType.military-spouse': butterDictionary.C20

  })[languageKey]

  return value ? value : "Error: Cannot resolve " + languageKey
}

function getDictWP(dictItem, paramObj) {
  /**
   * 2022-12-04 John Yee
   *
   * function name inspired by: get Dictionary_item With Parameters
   *
   * Replaces parameters in the string dictItem with the values identified in the object paramObj.
   * Format of the parameters in dictItem:  "{"+parameter_name+"}"
   * Example: {leoEmail}
   *
   * The keys in paramObj must match the parameter names in the dictionary item.
   * Example: paramObj = { leoEmail: "clerk@mytown.mystate" }
   */
  if (typeof dictItem !== "string") {
    return ""
  }
  if (dictItem === "" || dictItem === null) {
    return ""
  }

  let str = dictItem.replace(/{\s+/gi, '{').replace(/\s+}/gi, '}')
  let keys = Object.keys(paramObj)
  if (keys.length) {
    for (let ii = 0; ii < keys.length; ii++) {
      str = str.replace(new RegExp('{' + keys[ii] + '}', 'gi'), paramObj[keys[ii]])
    }
  }
  str = str.replace(/\n/gi, '<br>').replace(/\t/gi, '&emsp;')
  str = str.replace(/{(.*?)}/g, "").replace(/{}/gi, '') // finally remove all unused parameters
  return str
}

function getIOSDevice() {
  /**
   * 2023-11-09 John Yee
   * inspired by
   * https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
   * answered May 29, 2020 at 21:20
   * Bob Arlof
   */
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent)
  const isAppleDevice = navigator.userAgent.includes('Macintosh')
  const isTouchScreen = navigator.maxTouchPoints >= 1   // true for iOS 13 (and hopefully beyond)
  const iosQuirkPresent = function () {
    var audio = new Audio()
    audio.volume = 0.5
    return audio.volume === 1   // volume cannot be changed from "1" on iOS 12 and below
  };

  return isIOS || (isAppleDevice && (isTouchScreen || iosQuirkPresent())) || isAppleDevice
}

export {
  getA11yDictionary,
  getBanners,
  getChosenState,
  getDeadlineElection,
  getDeadlines,
  getDictionary,
  getDictFLJ,
  getDictWP,
  getElections,
  getGPPParameters,
  getIOSDevice,
  getLang,
  getLastElection,
  getNewVfaDate,
  getNextElection,
  getUpcomingElections,
  getUpcomingElectionsFiltered,
  getStateBallotRequestSubmitOptions,
  getStateRegistrationSubmitOptions,
  getStateRules,
  getStateRulesFieldDescriptions,
  getWrappedLines,
  reformatRules,
  retrieveButter,
  updateFvapLeosWithButter
}
