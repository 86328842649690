
export default {
  props: ['error', 'dict'],
  layout: 'default',
  mounted () {
    if (this.error == null) {
    }
    if (this.error.statusCode === 404) {
    }
    // webpack error
    if (this.error.statusCode === 500 && /^Loading chunk (\d)+ failed\./.test(this.error.message)) {
      this.isChunkLoadingFailed = true
      setTimeout(() => {
        location.replace(location.href)
      }, 3000)
    }
    this.showError = true
  },
  data () {
    return {
      showError: false,
      isChunkLoadingFailed: false
    }
  },
  computed: {
    statusCode () {
      return (this.error && this.error.statusCode) || 500
    },
    message () {
      return this.error.message
    }
  }

}
